import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";
import tokenAbi from "./tokenAbi.json";
import {
  stakeTokenAddress,
  stakingAddress,
  tokenAddress,
  claimingAddress,
} from "./environment";
import { stakeTokenAbi, stakingAbi, claimingAbi } from "./abi";

export const tokenReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: tokenAddress,
    abi: tokenAbi,
    chainId: 1,
    functionName,
    args,
  });
  return data;
};

export const preSaleReadFunction = async (obj, functionName, args) => {
  const data = await readContract({
    address: obj?.presaleAddress,
    abi: obj?.presaleAbi,
    chainId: obj?.chainId,
    functionName,
    args,
  });
  return data;
};

export const usdtReadFunction = async (obj, functionName, args) => {
  const data = await readContract({
    address: obj?.usdtAddress,
    abi: obj?.usdtAbi,
    chainId: obj?.chainId,
    functionName,
    args,
  });
  return data;
};
export const usdcReadFunction = async (obj, functionName, args) => {
  const data = await readContract({
    address: obj?.usdcAddress,
    abi: obj?.usdcAbi,
    chainId: obj?.chainId,
    functionName,
    args,
  });
  return data;
};

export const stakeTokenReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: stakeTokenAddress,
    abi: stakeTokenAbi,
    functionName,
    chainId: 1,
    args,
  });
  return data;
};

export const stakingReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: stakingAddress,
    abi: stakingAbi,
    functionName,
    chainId: 1,
    args,
  });
  return data;
};
export const claimingReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: claimingAddress,
    abi: claimingAbi,
    functionName,
    chainId: 1,
    args,
  });
  return data;
};
/// write functions
export const tokenWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: tokenAddress,
    abi: tokenAbi,
    chainId: 1,
    functionName,
    args,
  });

  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const preSaleWriteFunction = async (obj, functionName, args, value) => {
  const { hash } = await writeContract({
    address: obj?.presaleAddress,
    abi: obj?.presaleAbi,
    chainId: obj?.chainId,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const usdtWriteFunction = async (obj, functionName, args) => {
  const { hash } = await writeContract({
    address: obj?.usdtAddress,
    abi: obj?.usdtAbi,
    chainId: obj?.chainId,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const usdcWriteFunction = async (obj, functionName, args) => {
  const { hash } = await writeContract({
    address: obj?.usdcAddress,
    abi: obj?.usdcAbi,
    chainId: obj?.chainId,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const stakeTokenWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: stakeTokenAddress,
    abi: stakeTokenAbi,
    functionName,
    chainId: 1,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const stakingWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: stakingAddress,
    abi: stakingAbi,
    functionName,
    chainId: 1,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
export const claimingWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: claimingAddress,
    abi: claimingAbi,
    functionName,
    chainId: 1,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
