import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useContext, useEffect, useState } from "react";
import {
  bnbIcon,
  cardLogo,
  ethIcon,
  logo,
  usdcIcon,
  usdtIcon,
} from "./SmallComponents/Images";
import {
  StyledButton,
  StyledInput,
  StyledSelect,
  ToastNotify,
} from "./SmallComponents/AppComponents";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { AppContext, getNetworkContract } from "../utils";
import { encodeFunctionData, formatUnits, parseUnits } from "viem";
import {
  bscPresaleAddress,
  cardContractAddress,
  defaultAddress,
} from "../ConnectivityAssets/environment";
import Loading from "./SmallComponents/loading";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer/";
import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import cardContractAbi from "../ConnectivityAssets/cardContractAbi.json";
import { useSwitchNetwork } from "wagmi";
import {
  claimingReadFunction,
  claimingWriteFunction,
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdcReadFunction,
  usdcWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { bscPresaleAbi } from "../ConnectivityAssets/abi";

function PresaleBox() {
  window.Buffer = Buffer;
  const { switchNetwork } = useSwitchNetwork();
  const matches = useMediaQuery("(max-width:650px)");
  // let account = "0xf95b2D1a3517Cc71ba6f18F827594C479f6d9d9c";
  const { account, chainId } = useContext(AppContext);
  const { open } = useWeb3Modal();
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [inputSrc, setInputSrc] = useState("");
  const [buyWith, setBuyWith] = useState("USDT");
  const [endTime, setEndTime] = useState(1710947541);
  const [amount, setAmount] = useState("");
  const [preSaleEndedStatus, setPresaleEndedStatus] = useState(false);
  const [recivedTokens, setreceivedTokens] = useState(0);
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [tokenPerETH, settokenPerETH] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [currentStage, setcurrentStage] = useState(0);
  const [loading, setloading] = useState(false);
  const [amountRaisedForStage, setamountRaisedForStage] = useState(0);
  const [totalSoldTokensForStage, setTotalSoldTokensForStage] = useState(0);
  const [tokensToSellForStage, settokensToSellForStage] = useState(0);
  const [amountToRaiseForStage, setamountToRaiseForStage] = useState(0);
  const [progressBarForStage, setprogressBarForStage] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [callFunction, setCallFunction] = useState(false);
  const [dataLoading, setdataLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /// wert configration start here
  useEffect(() => {
    if (account) {
      (async () => {
        try {
          let refAddress = localStorage.getItem("KASPA");
          let refAdd = refAddress ? refAddress : defaultAddress;
          let amountToBuy = amount && +amount > 0 ? +amount : 1;

          const sc_input_data = encodeFunctionData({
            abi: bscPresaleAbi,
            functionName: "buyTokenCard",
            args: [
              account,
              refAdd,
              parseUnits(recivedTokens?.toString(), 18)?.toString(),
            ],
            value: parseUnits(amountToBuy.toString(), 8).toString(),
          });
          setInputSrc(sc_input_data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [account, amount, recivedTokens]);

  const privateKey =
    "0x90aab8b81c31e9dd93281c82e723d7f023fecab98fe092ce3480df8270540ad9";

  const signedData = signSmartContractData(
    {
      address: account,
      commodity: "BNB",
      network: "bsc",
      commodity_amount: amount ? parseFloat(amount) : 0,
      sc_address: bscPresaleAddress,
      sc_input_data: inputSrc,
    },
    privateKey
  );
  const otherWidgetOptions = {
    partner_id: "01HWA1S9WQ33EW5MQZSHS79YTN",
    widgetLayoutMode: "Modal",
    click_id: uuidv4(), // unique id of purhase in your system
    origin: "https://widget.wert.io", // this option needed only for this example to work
    extra: {
      item_info: {
        author: "Kaspa Nexus",
        image_url:
          "https://photos.pinksale.finance/file/pinksale-logo-upload/1713870981373-1bb87d41d15fe27b500a4bfcde01bb0e.png",
        name: "Token Payment",
        category: "Kaspa Nexus",
      },
    },
  };

  const wertWidget = new WertWidget({
    ...signedData,
    ...otherWidgetOptions,
  });

  /// wert configration ends here

  const initVoidSigner = async () => {
    try {
      setdataLoading(true);
      const networkContractObj = getNetworkContract(chainId);
      const ethNetworkContractObj = getNetworkContract(1);
      const bscNetworkContractObj = getNetworkContract(56);
      let dec = await tokenReadFunction("decimals");
      dec = +dec.toString();
      let stage = await preSaleReadFunction(networkContractObj, "currentStage");
      setcurrentStage(+stage?.toString());
      let usdtToToken = await preSaleReadFunction(
        networkContractObj,
        "usdtToToken",
        [
          chainId > 0 && chainId !== 56 ? "1000000" : "1000000000000000000",
          stage?.toString(),
        ]
      );
      settokenPerUSDT(+formatUnits(usdtToToken?.toString(), 18));
      let ethToToken = await preSaleReadFunction(
        networkContractObj,
        "nativeToToken",
        ["1000000000000000000", stage?.toString()]
      );
      settokenPerETH(+formatUnits(ethToToken?.toString(), dec));
      let bscPresaleDataContract = await preSaleReadFunction(
        bscNetworkContractObj,
        "phases",
        [+stage?.toString()]
      );
      let presaleDataContract = await preSaleReadFunction(
        ethNetworkContractObj,
        "phases",
        [+stage?.toString()]
      );
      settokenPrice(+formatUnits(presaleDataContract[0]?.toString(), dec));
      // setEndTime(+presaleData[1]?.toString());
      let toSell = +parseFloat(
        +formatUnits(presaleDataContract[1]?.toString(), dec)
      )?.toFixed(0);
      let toRaise = +parseFloat(
        +formatUnits(presaleDataContract[1]?.toString(), dec) /
          +formatUnits(presaleDataContract[0]?.toString(), dec)
      ).toFixed(0);

      let bscRaised =
        +formatUnits(bscPresaleDataContract[2]?.toString(), dec) > 0
          ? +parseFloat(
              +formatUnits(bscPresaleDataContract[2]?.toString(), dec) /
                +formatUnits(bscPresaleDataContract[0]?.toString(), dec)
            ).toFixed(0)
          : 0;

      let raised =
        +formatUnits(presaleDataContract[2]?.toString(), dec) > 0
          ? +parseFloat(
              +formatUnits(presaleDataContract[2]?.toString(), dec) /
                +formatUnits(presaleDataContract[0]?.toString(), dec)
            ).toFixed(0)
          : 0;

      raised = raised + bscRaised;
      let sold =
        +parseFloat(
          +formatUnits(presaleDataContract[2]?.toString(), dec)
        )?.toFixed(0) +
        +parseFloat(
          +formatUnits(bscPresaleDataContract[2]?.toString(), dec)
        )?.toFixed(0);
      let prog = (+raised / toRaise) * 100;
      setprogressBarForStage(+prog);
      settokensToSellForStage(toLocalFormat(+toSell));
      setamountToRaiseForStage(toLocalFormat(+toRaise));
      setamountRaisedForStage(toLocalFormat(+raised));
      setTotalSoldTokensForStage(toLocalFormat(+sold));
      const preSaleStatusContract = await preSaleReadFunction(
        networkContractObj,
        "isPresaleEnded"
      );
      setPresaleEndedStatus(preSaleStatusContract);
      setdataLoading(false);
      setCallFunction(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initVoidSigner();
    setCallFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction, chainId]);

  const userTokenFunction = async () => {
    try {
      const networkContractETH = getNetworkContract(1);
      let dec = await tokenReadFunction("decimals");
      // let claimingData = await claimingReadFunction(
      //   networkContractETH,
      //   "users",
      //   [account]
      // );
      let userDataETH = await preSaleReadFunction(networkContractETH, "users", [
        account,
      ]);
      let total = +formatUnits(userDataETH[4]?.toString(), +dec?.toString());
      // +formatUnits(claimingData[0]?.toString(), +dec?.toString()) +
      setuserPurchasedTokens(parseFloat(total)?.toFixed(0));
      // let stakedTokenAmount = 0;
      // for (let i = 0; i <= +userData[6]?.toString(); i++) {
      //   const userData = await preSaleReadFunction(
      //     networkContractObj,
      //     "userStakes",
      //     [account, i]
      //   );

      //   stakedTokenAmount += +parseFloat(
      //     formatUnits(userData[0]?.toString(), 18)
      //   );
      // }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      userTokenFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  const buyHandler = async (stakingStatus) => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!amount || amount <= 0) {
      return showAlert("Error! Please enter amount to buy.");
    }
    try {
      let refAddress = localStorage.getItem("KASPA");
      let refAdd = refAddress ? refAddress : defaultAddress;
      const networkContractObj = getNetworkContract(chainId);
      setloading(true);
      if (buyWith === "CARD") {
        wertWidget.open();
      } else {
        if (buyWith === "USDT") {
          const param =
            chainId !== 56
              ? [
                  parseUnits(amount.toString(), 6).toString(),
                  stakingStatus,
                  refAdd,
                ]
              : [parseUnits(amount.toString(), 6).toString(), refAdd];
          const usdtDecimal = await usdtReadFunction(
            networkContractObj,
            "decimals"
          );
          await usdtWriteFunction(networkContractObj, "approve", [
            networkContractObj?.presaleAddress,
            parseUnits(amount.toString(), +usdtDecimal?.toString()).toString(),
          ]);
          await preSaleWriteFunction(networkContractObj, "buyTokenUSDT", param);
        } else if (buyWith === "USDC") {
          const usdcDecimal = await usdcReadFunction(
            networkContractObj,
            "decimals"
          );

          await usdcWriteFunction(networkContractObj, "approve", [
            networkContractObj?.presaleAddress,
            parseUnits(amount.toString(), +usdcDecimal?.toString()).toString(),
          ]);

          const param =
            chainId !== 56
              ? [
                  parseUnits(
                    amount.toString(),
                    +usdcDecimal?.toString()
                  ).toString(),
                  stakingStatus,
                  refAdd,
                ]
              : [
                  parseUnits(
                    amount.toString(),
                    +usdcDecimal?.toString()
                  ).toString(),
                  refAdd,
                ];
          await preSaleWriteFunction(networkContractObj, "buyTokenUSDC", param);
        } else {
          const tokenDecimal = await tokenReadFunction("decimals");
          const param = chainId !== 56 ? [stakingStatus, refAdd] : [refAdd];
          await preSaleWriteFunction(
            networkContractObj,
            "buyToken",
            param,
            parseUnits(amount.toString(), +tokenDecimal?.toString()).toString()
          );
        }
      }
      setAmount("");
      setreceivedTokens(0);
      setCallFunction(true);
      userTokenFunction();
      setloading(false);
      if (buyWith !== "CARD") {
        showAlert("Success! Transaction Confirmed", "success");
      }
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };

  const claimRewardHandler = async () => {
    try {
      if (!account) {
        return showAlert("Error! Please connect your wallet.");
      }
      const ethNetworkContractObj = getNetworkContract(1);
      setloading(true);
      let claimingData = await claimingReadFunction("users", [account]);
      let userDataETH = await preSaleReadFunction(
        ethNetworkContractObj,
        "users",
        [account]
      );
      if (+claimingData[2]?.toString() > 0) {
        await claimingWriteFunction("claimRefReward");
        showAlert("Success! Claim successful", "success");
      }
      if (+userDataETH[3]?.toString() > 0) {
        await preSaleWriteFunction(ethNetworkContractObj, "claimRefReward");
        showAlert("Success! Claim successful", "success");
      }
      if (
        +claimingData[2]?.toString() <= 0 &&
        +userDataETH[3]?.toString() <= 0
      ) {
        showAlert("Error! No Amount To Claim", "error");
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };

  const claimTokensHandler = async () => {
    try {
      if (!account) {
        return showAlert("Error! Please connect your wallet.");
      }
      const ethNetworkContractObj = getNetworkContract(1);
      setloading(true);
      let claimingData = await claimingReadFunction("users", [account]);
      let userDataETH = await preSaleReadFunction(
        ethNetworkContractObj,
        "users",
        [account]
      );
      if (+claimingData[0]?.toString() > 0) {
        await claimingWriteFunction("claimTokens");
        showAlert("Success! Claim successful", "success");
      }
      if (+userDataETH[4]?.toString() > 0) {
        await preSaleWriteFunction(ethNetworkContractObj, "claimTokens");
        showAlert("Success! Claim successful", "success");
      }
      if (
        +claimingData[0]?.toString() <= 0 &&
        +userDataETH[4]?.toString() <= 0
      ) {
        showAlert("Error! No Amount To Claim", "error");
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };
  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setAmount(newValue);
  };
  const copyAddressHandler = () => {
    navigator.clipboard.writeText(window.location + "/?ref=" + account);
    showAlert("Address Copied", "success");
  };

  useEffect(() => {
    if (chainId === 56 || chainId === 1) {
      setSelectedNetwork(chainId);
    } else {
      setSelectedNetwork("");
    }
    setAmount("");
  }, [chainId]);
  const handleFormChange = (event) => {
    switchNetwork?.(event.target.value);
  };

  useEffect(() => {
    const calculatorUSDT = async () => {
      try {
        if (buyWith === "USDT" || buyWith === "USDC") {
          let tokenUSDT = +tokenPerUSDT * +amount;
          setreceivedTokens(tokenUSDT?.toFixed(0));
        } else {
          let tokenETH = +tokenPerETH * +amount;
          setreceivedTokens(tokenETH?.toFixed(0));
        }
      } catch (error) {}
    };
    if (+amount > 0) {
      calculatorUSDT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, buyWith]);

  useEffect(() => {
    (() => {
      if (window.location.href.includes("?ref=")) {
        let getAddress = window.location.href.split("?ref=")[1];
        let final = getAddress.slice(0, 42);
        localStorage.setItem("KASPA", final);
      }
    })();
  }, []);
  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Stack
        mt={4}
        sx={{
          background: "#0d1824",
          px: { xs: 2, sm: 4 },
          py: 4,
          borderRadius: "12px",

          gap: 2,
        }}
      >
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          gap={{ xs: 1, sm: 0 }}
          alignItems={{ xs: "start", md: "center" }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#EBE9ED",
              fontSize: { xs: "20px", sm: "28px" },
              fontFamily: "Outfit",
              lineHeight: { xs: "32px", sm: "36px" },
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            PreSale{" "}
            <span
              style={{
                color: "#68bcbb",
              }}
            >
              Stage {currentStage + 1}
            </span>
          </Typography>

          <Box
            sx={{
              backgroundColor: "#0F101E",
              borderRadius: "12px",
              width: { xs: "97%", md: "fit-content" },
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "space-between", md: "center" },
              m: { xs: "auto", md: "0px" },
              px: { xs: 1.5, md: 3 },
              gap: { xs: 0, sm: 0.8 },
              py: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#EBE9ED",
                fontSize: "20px",
                fontFamily: "Outfit",
                lineHeight: "32px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "#68bcbb",
                }}
              >
                1
              </span>{" "}
              $KSPNX ={" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#EBE9ED",
                fontSize: "20px",
                fontFamily: "Outfit",
                lineHeight: "32px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              {" "}
              <span
                style={{
                  color: "#68bcbb",
                }}
              >
                $
                {+tokenPrice > 0
                  ? parseFloat(1 / +tokenPrice).toFixed(4)
                  : "0.00"}
              </span>
            </Typography>
          </Box>
        </Stack>

        <Box
          sx={{
            backgroundColor: { xs: "#0F101E", md: "transparent" },
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: { xs: "auto", md: "0px" },
            px: { xs: 1.5, md: 3 },
            gap: { xs: 0, sm: 0.8 },
            mt: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#EBE9ED",
              fontSize: "20px",
              fontFamily: "Outfit",
              lineHeight: "32px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Listing Price ={" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#EBE9ED",
              fontSize: "20px",
              fontFamily: "Outfit",
              lineHeight: "32px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#68bcbb",
              }}
            >
              $0.05
            </span>
          </Typography>
        </Box>

        <Stack
          sx={{
            height: "30px",
            backgroundColor: "#0F101E",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <Stack
            height={"100%"}
            alignItems={"start"}
            justifyContent={"center"}
            position={"relative"}
          >
            <Box
              sx={{
                width: `${progressBarForStage}%`,
                height: "100%",
                borderRadius: "20px",
                background:
                  "linear-gradient(92.51deg, #68bcbb 0.48%, #68bcbb 50.74%, #68bcbb 100%)",
                display: "flex",
                justifyContent: "end",
                py: 0.5,
                alignItems: "center",
              }}
            >
              {progressBarForStage >= 12 && (
                <Box
                  sx={{
                    backgroundColor: "#fef7f6",
                    textAlign: "center",
                    px: 1,
                    py: 0.5,
                    mr: 0.5,
                    width: "fit-content",
                    fontFamily: "Outfit",
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "#000000",
                    borderRadius: "12px",
                  }}
                >
                  {parseFloat(progressBarForStage)?.toFixed(3)}%
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
        <Stack
          flexDirection={{ xs: "row", sm: "row" }}
          justifyContent={"space-evenly"}
          gap={{ xs: 1, sm: 0 }}
          alignItems={"center"}
        >
          <Stack gap={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#75717F",
                fontSize: { xs: "14px" },
                fontFamily: "Outfit",
                lineHeight: "24px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Current Stage Sold
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                color: "#EBE9ED",
                fontSize: "20px",
                fontFamily: "Outfit",
                lineHeight: "32px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {dataLoading ? (
                "---"
              ) : (
                <>
                  <span
                    style={{
                      color: "#68bcbb",
                    }}
                  >
                    {toLocalFormat(totalSoldTokensForStage)}
                  </span>
                  {matches && <br />}/ {tokensToSellForStage}
                </>
              )}
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#75717F",
                fontSize: { xs: "14px" },
                fontFamily: "Outfit",
                lineHeight: "24px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Current Stage Raised
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                color: "#EBE9ED",
                fontSize: "20px",
                fontFamily: "Outfit",
                lineHeight: "32px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {dataLoading ? (
                "---"
              ) : (
                <>
                  <span
                    style={{
                      color: "#68bcbb",
                    }}
                  >
                    ${toLocalFormat(amountRaisedForStage)}
                  </span>{" "}
                  {matches && <br />}/ {amountToRaiseForStage}
                </>
              )}
            </Typography>
          </Stack>
        </Stack>

        <Divider
          sx={{
            background: "#3F3947",
            height: "1px",
            mt: 1,
          }}
        />
        {/* <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          gap={{ xs: 1, sm: 1 }}
          alignItems={{ xs: "start", md: "center" }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#EBE9ED",
              fontSize: { xs: "18px", sm: "22px" },
              fontFamily: "Outfit",
              lineHeight: { xs: "32px", sm: "36px" },
              fontWeight: "600",
            }}
          >
            Buy With
          </Typography>
          <Stack
            sx={{
              background: "#0F101E",
              flexDirection: "row",
              borderRadius: "12px",
              justifyContent: "space-around",
              py: 1,
              px: 1,
              width: { xs: "95%", md: "fit-content" },
            }}
          >
            {[
              {
                text: chainId === 56 ? "USDT" : "USDC",
                img: chainId === 56 ? usdtIcon : usdcIcon,
              },
              {
                text: chainId === 56 ? "BNB" : "ETH",
                img: chainId === 56 ? bnbIcon : ethIcon,
              },
              {
                text: "CARD",
                img: cardLogo,
              },
            ].map(({ text, img }) => (
              <Stack
                onClick={() => setBuyWith(text)}
                key={text}
                sx={{
                  cursor: "pointer",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  borderRadius: "12px",
                  py: { xs: 1, sm: 1 },
                  px: { xs: 2, md: 3 },
                  background: buyWith === text ? "#514e57" : "transparent",
                }}
              >
                <Box component={"img"} alt="" src={img} width="20px" />
                <Typography
                  variant="subtitle2"
                  sx={{
                    mt: 0.5,
                    color: "#EBE9ED",
                    fontFamily: "Outfit",
                    fontSize: { xs: "14px", sm: "14px" },
                    fontWeight: buyWith === text ? "400" : "700",
                    lineHeight: "24px",
                  }}
                >
                  {text}
                </Typography>
              </Stack>
            ))}
          </Stack>{" "}
        </Stack> */}
        {/* <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={{ xs: 1, md: 1 }}
          gap={{ xs: 2, sm: 3 }}
        >
          <Stack>
            <Box>
              <StyledInput
                type="text"
                placeholder="0.00"
                color="#EBE9ED"
                borderColor={"#68bcbb"}
                value={amount}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={
                          buyWith === "USDC"
                            ? usdcIcon
                            : buyWith === "USDT"
                            ? usdtIcon
                            : buyWith === "BNB"
                            ? bnbIcon
                            : buyWith === "ETH"
                            ? ethIcon
                            : cardLogo
                        }
                        sx={{
                          width: "30px",
                          mr: { xs: 2, md: 3 },
                          ml: { xs: "-10px", md: "0px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>

          <Stack>
            <Box>
              <StyledInput
                color="#66656D"
                borderColor={"#312E37"}
                type="text"
                placeholder="0"
                value={amount > 0 ? recivedTokens : "0"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component={"img"}
                        alt=""
                        src={logo}
                        sx={{
                          width: "30px",
                          mr: { xs: 2, md: 3 },
                          ml: { xs: "-10px", md: "0px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Stack> */}
        <Stack mt={1} gap={1.5}>
          {/* {account && (
            <Box mt={1}>
              <StyledSelect
                displayEmpty
                fullWidth
                value={selectedNetwork}
                onChange={handleFormChange}
              >
                <MenuItem value="" disabled sx={{ display: "none" }}>
                  {+chainId === 1 ? (
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Box
                        component={"img"}
                        alt=""
                        src={ethIcon}
                        width="22px"
                      />
                      Buy With ETH
                    </Stack>
                  ) : (
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Box
                        component={"img"}
                        alt=""
                        src={bnbIcon}
                        width="22px"
                      />
                      Buy With BNB
                    </Stack>
                  )}
                </MenuItem>
                {[
                  {
                    img: ethIcon,
                    text: "Buy with ETH",
                    chainId: 1,
                  },
                  {
                    img: bnbIcon,
                    text: "Buy with BNB",
                    chainId: 56,
                  },
                ].map(({ img, text, chainId: chainNumber }) => (
                  <MenuItem value={chainNumber} key={text}>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <Box component={"img"} alt="" src={img} width="22px" />
                      {text}
                    </Stack>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
          {chainId !== 1 && !preSaleEndedStatus && (
            <StyledButton
              width="100%"
              onClick={async () => (account ? buyHandler(false) : await open())}
            >
              {" "}
              {account ? "BUY" : "CONNECT WALLET"}
            </StyledButton>
          )}

          {chainId === 1 && !preSaleEndedStatus && (
            <StyledButton
              width="100%"
              onClick={async () => (account ? buyHandler(true) : await open())}
            >
              {" "}
              {account ? "BUY & STAKE" : "CONNECT WALLET"}
            </StyledButton>
          )} */}
          {preSaleEndedStatus && (
            <Stack gap={1}>
              <StyledButton
                width="100%"
                onClick={async () =>
                  account ? claimTokensHandler() : await open()
                }
              >
                {" "}
                {account ? "Claim Tokens" : "CONNECT WALLET"}
              </StyledButton>
              {account && (
                <StyledButton
                  width="100%"
                  onClick={async () => claimRewardHandler()}
                >
                  Claim Ref Reward
                </StyledButton>
              )}
            </Stack>
          )}
        </Stack>
        <Stack mt={1}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#75717F",
              fontSize: "14px",
              fontFamily: "Outfit",
              lineHeight: "24px",
              fontWeight: "400",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Your Balance{" "}
            <span
              style={{
                color: "#68bcbb",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "32px",
                marginLeft: "15px",
              }}
            >
              {toLocalFormat(userPurchasedTokens)} KSPNX
            </span>{" "}
          </Typography>
        </Stack>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            lineHeight: "15px",
            fontFamily: "Outfit",
          }}
        >
          Earn FREE KSPNX by referring your friends!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            lineHeight: "18px",
            fontFamily: "Outfit",
          }}
        >
          Share your unique link below and receive 10% bonus from all referred
          investors!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            lineHeight: "15px",
            fontFamily: "Outfit",
          }}
        >
          Please Connect your wallet to reveal your referral link.
        </Typography>
        {account && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#75717F",
                fontSize: "14px",
                fontFamily: "Outfit",
                lineHeight: "24px",
                fontWeight: "400",
                textAlign: "center",
                mb: -2,
              }}
            >
              Your Referral Link
            </Typography>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#110f1652",
                padding: "7px 12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: "16px",
                  fontFamily: "Outfit",
                  color: "#fff",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {window.location + "/?ref=" + account}
              </Box>
              <IconButton sx={{ ml: 2 }} onClick={copyAddressHandler}>
                <ContentCopyIcon
                  sx={{
                    color: "#ffffff",
                    fontSize: "20px",
                  }}
                />
              </IconButton>
            </Box>
          </>
        )}
      </Stack>
    </>
  );
}

export default PresaleBox;
